import { defineStore } from 'pinia'
import { useMarketStore } from '@/stores/market'
import { useDcmStore } from '@/stores/dcm'
import { useCustomer } from '@/stores/customer'

export const useFeatureMGMStore = defineStore('feature-mgm', {
  state: () => ({
    features: [],
    pre_scoring_banner: {},
  }),
  actions: {
    async getFeatures() {
      const customerStore = useCustomer()
      if (customerStore.isLoggedIn) {
        if (process.server) {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.NODE_FEATURE_MGM_BASE_URL ||
            this.$nuxt.$config.FEATURE_MGM_BASE_URL
        } else {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.FEATURE_MGM_BASE_URL
        }
        try {
          const { data } = await this.$nuxt.$axios.get('api/features')
          let feature_manager = ''
          const feature_data = data?.[0]
          if (feature_data && this.features.length === 0) {
            Object.keys(feature_data).forEach(key => {
              feature_manager = `${feature_manager} ${feature_data[key]}`
            })
            if (typeof dataLayer !== 'undefined') {
              dataLayer.push({
                event: 'feature_manager',
                feature_code: feature_manager,
              })
            }
          }
          const marketStore = useMarketStore()
          if (
            marketStore.anonFavoriteList &&
            marketStore.anonFavoriteList.length
          ) {
            const list_anon_favorite_id = marketStore.anonFavoriteList.map(
              id => ({
                product_id: id,
              }),
            )
            await marketStore.setAnonFavoriteProductsMultiple(
              list_anon_favorite_id,
            )
            await marketStore.getFavorites()
          }

          this.features = data
        } catch (e) {
          console.log('Error: getFeatures', e)
        }
      }
    },
    async getPreScoringBanner() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_BROKER_BASE_URL ||
          this.$nuxt.$config.BROKER_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
      }
      let timeout = false
      const timeoutId = setTimeout(() => {
        timeout = true
      }, 2000)
      try {
        const user = this.$nuxt.$auth.$storage.getState('user')
        if (user) {
          const { data, status } = await this.$nuxt.$axios.get(
            'api/v3/pre_scoring',
            {
              params: {
                customer_ext_id: user.ext_id,
                phone_number: user.phone.replace('+', ''),
              },
            },
          )
          if (data?.no_lead_kb) {
            const dcmStore = useDcmStore()
            await dcmStore.getCreditLineBannerImage()
          }

          clearTimeout(timeoutId)
          if (!timeout && [200, 202].includes(status)) {
            let feature_manager = ''
            if (data) {
              Object.keys(data).forEach(key => {
                feature_manager = `${feature_manager} ${data[key]}`
              })
              if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                  event: 'feature_manager',
                  feature_code: feature_manager,
                })
              }
            }
            this.pre_scoring_banner = data
          } else {
            this.pre_scoring_banner = {}
          }
        }
      } catch (e) {
        if (timeout)
          console.log('Error: getPreScoringBanner request timeout', e)
        else console.log('Error: getPreScoringBanner', e)
        this.pre_scoring_banner = {}
      }
    },
  },
})
