
import { mapGetters } from 'vuex'
import { mapState } from 'pinia'
import { useCheckoutCredit } from '@/stores/checkout_credit'
import { formatCurrency } from '@/utilities'

export default {
  name: 'UserCreditLimit',
  fetch() {
    if (process.client && !this.credit_user_balance?.avail_limit_max) {
      const CheckoutCredit = useCheckoutCredit()
      CheckoutCredit.CHECK_USER_CREDIT_BALANCE()
    }
  },
  methods: { formatCurrency },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    ...mapState(useCheckoutCredit, ['credit_user_balance']),
    maxCreditLimit() {
      return this.credit_user_balance?.avail_limit_max
    },
  },
}
