import { mapGetters } from 'vuex'
import { useUiStore } from '@/stores/ui'

export default {
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      user: 'loggedInUser',
    }),
    fullYearsCookie() {
      if (this.$auth.$storage.getLocalStorage('fullYearsCookie')) {
        return JSON.parse(
          `{${this.$auth.$storage.getLocalStorage('fullYearsCookie')}}`,
        )
      } else {
        return false
      }
    },
    fullYears() {
      let result
      if (this.user) {
        if (this.user?.birthdate === null) {
          return this.$auth.$storage.getLocalStorage('fullYearsCookie')
            ? this.fullYearsCookie.value
            : false
        } else {
          const years =
            (new Date() - new Date(this.user.birthdate)) /
            (24 * 3600 * 365.25 * 1000)
          years >= 18 ? (result = true) : (result = false)
          return result
        }
      } else if (this.$auth.$storage.getLocalStorage('fullYearsCookie')) {
        return this.fullYearsCookie.value
      } else {
        return false
      }
    },
  },
  methods: {
    isAdultPopup() {
      const uiStore = useUiStore()
      if (!this.isAuthenticated && this.is_adult && this.fullYears == false) {
        uiStore.isAdultPopupVisible = true
      } else if (
        this.isAuthenticated &&
        this.is_adult &&
        this.fullYears === true
      ) {
        uiStore.isAdultPopupVisible = false
      } else if (
        this.isAuthenticated &&
        this.is_adult &&
        this.fullYears == false
      ) {
        uiStore.isAdultPopupVisible = true
      }
    },
  },
  mounted() {
    if (this.user) {
      this.isAdultPopup()
    } else {
      setTimeout(() => {
        this.isAdultPopup()
      }, 500)
    }
  },
}
