
export default {
  name: 'MPBreadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    root: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    let active = document.getElementsByClassName('MarketHeader-Breadcrumbs')
    if (active) {
      active = active[0]
      this.$nextTick(() => {
        setTimeout(() => {
          if (active) {
            active.scrollLeft = active.scrollWidth
          }
        }, 1000)
      })
    }
  },
  methods: {
    isCurrent(breadcrumb) {
      return breadcrumb && breadcrumb.current
    },
    url(breadcrumb) {
      return {
        name: 'categories-slug',
        params: {
          slug: `${breadcrumb.id}-${breadcrumb.slug}`,
        },
      }
    },
  },
}
