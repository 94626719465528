export default {
  methods: {
    pageViewGTM() {
      const {
        referrer: page_referrer = '',
        location: { href: page_url = '' },
      } = document
      const page_title = this.$root?.$meta()?.refresh()?.metaInfo?.title
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'page_view',
          page_title,
          page_referrer,
          page_url,
        })
      }
    },
  },
  mounted() {
    this.pageViewGTM()
  },
}
