import { mapState } from 'pinia'
import { useMarketStore } from '~/stores/market'

export default {
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  computed: {
    priceValidUntilDate() {
      const today = new Date()
      const nextYear = today.getFullYear() + 1
      const month = String(today.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
      const day = String(today.getDate()).padStart(2, '0')

      return `${nextYear}-${month}-${day}`
    },
  },
  methods: {
    getMainEntity() {
      const offers = []
      for (const product in this.marketStore.products) {
        const hasMerchantReturnPolicy = {
          applicableCountry: 'AZ',
          returnPolicyCategory: !this.marketStore.products[product]
            ?.non_refundable
            ? 'https://schema.org/MerchantReturnFiniteReturnWindow'
            : 'https://schema.org/MerchantReturnNotPermitted',
          returnMethod: 'https://schema.org/ReturnInStore',
          returnFees: 'https://schema.org/FreeReturn',
        }
        if (!this.marketStore.products[product]?.non_refundable) {
          hasMerchantReturnPolicy.merchantReturnDays = 14
        }
        const item = this.marketStore.products[product]
        if (!item || item.price === 0) continue
        offers.push({
          '@type': 'Offer',
          priceCurrency: 'AZN',
          price: item.retail_price,
          sku: item.id,
          availability: this.getAvailability(item),
          hasMerchantReturnPolicy,
          priceValidUntil: this.priceValidUntilDate,
        })
      }
      return offers
    },
    ldJsonProducts() {
      const images = []
      this.marketStore.products.forEach(item => {
        if (item?.img_url_thumbnail) {
          images.push(item.img_url_thumbnail)
        }
      })
      const offers = this.getMainEntity()
      if (!offers) return
      if (!offers || !Array.isArray(offers) || !offers.length) return null
      return {
        name: this.marketStore?.selfCategory?.data?.name,
        '@context': 'https://schema.org',
        '@type': 'Product',
        offers,
        image: images,
      }
    },
    getAvailability(item) {
      let availability = 'https://schema.org/InStock'
      if (
        item.status === 'active' &&
        item.qty >= item.default_show_stock_qty_threshold
      ) {
        availability = 'https://schema.org/InStock'
      } else if (item.status === 'out_of_stock') {
        availability = 'https://schema.org/OutOfStock'
      } else if (item.qty < item.default_show_stock_qty_threshold) {
        availability = 'https://schema.org/LimitedAvailability'
      }
      return availability
    },
    getSeoPopular() {
      if (!this.marketStore.products || !this.marketStore.products.length)
        return null
      const ldJson = this.ldJsonProducts()
      if (!ldJson) return null
      return {
        type: 'application/ld+json',
        json: ldJson,
      }
    },
  },
}
