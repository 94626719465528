
import { mapState, mapWritableState } from 'pinia'
import { useCustomer } from '~/stores/customer'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'
export default {
  name: 'MPHeader',
  props: {
    root: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    return {
      marketStore,
      uiStore,
    }
  },
  computed: {
    ...mapWritableState(useCustomer, ['geo']),
    ...mapState(useMarketStore, ['breadcrumbs']),
    showBack() {
      return (
        this.getRouteBaseName(this.$route) !== 'categories' &&
        this.$device.isMobile &&
        this.uiStore.fromBB &&
        this.breadcrumbsFiltered.length
      )
    },
    city() {
      return (this.geo && this.geo.cityName) || this.$t('baku')
    },
    paginationPage() {
      return this.marketStore.pagination?.page || 0
    },
    breadcrumbsFiltered() {
      return this.breadcrumbs.slice(0, this.breadcrumbs.length - 1)
    },
    header() {
      if (this.breadcrumbs && this.breadcrumbs.length) {
        const current = this.breadcrumbs.find(b => b.current)
        return this.$t('market.catalog_header_title', {
          city: this.city,
          category:
            (current && current.name) ||
            this.breadcrumbs[this.breadcrumbs.length - 1].name,
        })
      } else {
        return this.$t('market.catalog_categories')
      }
    },
  },
}
