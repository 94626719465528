
import { mapGetters } from 'vuex'
import adultCheck from '../../mixins/adultCheck'
import seoFaqMixin from './seo_faq'
import seoPopularProducts from './seo_popular_products'
import { INCLUDE_FIELDS, PER_PAGE, BANNERS_PRODUCTS_LIST } from '@/constants'
import pageViewGTM from '@/mixins/pageViewGTM'
import productFilters from '@/mixins/productFilters'
import setDataLayer from '@/mixins/setDataLayer'
import {
  rtbAnalytics,
  setViewSearchResultsSetup,
  filterQueryWhiteList,
  decodeQuery,
} from '~/utilities'
import { mapWritableState, mapActions, mapState, storeToRefs } from 'pinia'
import { useCustomer } from '~/stores/customer'
import { usePromoCodeStore } from '~/stores/promocode'
import { useLotteryStore } from '~/stores/lottery'
import { useFeatureMGMStore } from '~/stores/feature-mgm'
import { useBloomreachStore } from '~/stores/bloomreach'

import { useMarketStore } from '@/stores/market'
import { useUiStore } from '@/stores/ui'
function initBanners(ENVIRONMENT, categoryId) {
  setTimeout(() => {
    ;(window.globalAmlAds = window.globalAmlAds || []).push(
      BANNERS_PRODUCTS_LIST[ENVIRONMENT].init_banner(categoryId),
    )
  }, 1000)
}
export default {
  name: 'MPCatalogPageSingle',
  mixins: [
    pageViewGTM,
    seoFaqMixin,
    productFilters,
    setDataLayer,
    adultCheck,
    seoPopularProducts,
  ],
  beforeRouteLeave(to, from, next) {
    const nextLocation = to?.name.replace(`___${this.$i18n.locale}`)
    if (!nextLocation.includes('product-slug')) {
      this.marketStore.breadcrumbs = []
    }
    next()
  },
  layout: 'market',
  validate({ params, redirect }) {
    const strcompare = params.slug?.toLowerCase()
    if (strcompare !== params.slug) {
      redirect(301, { path: strcompare.trim() })
    } else {
      return /^[0-9]+-(.)+/.test(params.slug)
    }
  },
  setup() {
    const PromoCodeStore = usePromoCodeStore()
    const LotteryStore = useLotteryStore()
    const marketStore = useMarketStore()
    const { productFilters } = storeToRefs(marketStore)
    const bloomreachStore = useBloomreachStore()
    return {
      PromoCodeStore,
      LotteryStore,
      bloomreachStore,
      marketStore,
      productFilters,
    }
  },
  async asyncData({ $device, store, error, params, query, route }) {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    if (process.browser) {
      marketStore.loadingProducts = true
    }
    if (!process.browser && query) {
      marketStore.productFilters = query
    }
    if (query?.response_mode) {
      query['p[response_mode]'] = query.response_mode
      delete query.response_mode
    }
    let category
    let view_mode = ''
    let is_adult = false
    const slug = params.slug
    const matches = params.slug.match(/^([0-9]*)-(.*)/)
    const categoryId = matches && matches[1]
    const matchText = matches && matches[2]
    const urlLinkForMatch =
      route.path.split('/')[route.path.split('/').length - 1]
    if (!categoryId) error({ statusCode: 404, message: '' })

    const isFirstPage = !(query?.page > 1)
    await marketStore.getBreadcrumbs({
      categoryId,
      'q[opaque_id]': route?.path,
    })
    const featureMGMStore = useFeatureMGMStore()
    await featureMGMStore.getFeatures()
    try {
      const res = await marketStore.getCategories({
        start_node_id: categoryId,
        seo_text_need: isFirstPage,
        opaque_id: route?.path,
      })
      category = res?.data
    } catch (err) {
      console.log('ERROR:ASYNCDATA:categories_slug', err)
    }

    view_mode = category?.self?.data?.view_mode || 'categories'
    marketStore.currentCategoryName = category?.self?.data?.name || ''

    is_adult = category?.self?.data?.is_adult || false
    if (
      slug !==
      `${category?.self?.data?.id}-${category?.self?.data?.slugged_name}`
    )
      error({ statusCode: 404, message: '' })

    const promises = []

    let list_category_id_in = String(categoryId)
    category?.data.forEach(category => {
      if (category.ref_category_id)
        list_category_id_in = `${list_category_id_in},${category.ref_category_id}`
    })

    if (view_mode !== 'categories') {
      let options = {
        'q[category_id_in]': list_category_id_in,
        include_fields: `${INCLUDE_FIELDS},categories.full_path,loyalty_cashback,product_labels,categories,min_qty,discounted,offers.installment_enabled,offers.max_installment_months,offers.avail_check,offers.old_price,offers.old_price,non_refundable,offers.seller_id,offers.seller_marketing_name,market_lottery_default,market_lottery_premium,offers.product_offer_labels,categories.path_ids`,
        exclude_fields:
          'ratings.questions,ratings.assessment_id,ratings.product_id',
        per_page: uiStore.fromBB ? 8 : PER_PAGE,
      }
      if (view_mode === 'products_and_categories') {
        if (query['q[s]']) {
          options['q[s]'] = query['q[s]']
        } else {
          options['q[s]'] = 'global_popular_score desc'
        }
      } else if (query['q[s]']) {
        options['q[s]'] = query['q[s]']
      } else {
        options['q[s]'] = 'category_popular_score desc'
      }
      const statuses = {}
      // statuses['q[status_in]'] = 'out_of_stock,active,unavailable'
      statuses['q[market_order]'] = true
      const setQueryString = query
      Object.keys(query).forEach(key => {
        setQueryString[key] = Array.isArray(query?.[key])
          ? query?.[key]?.[0]
          : query?.[key]
      })
      options = {
        ...decodeQuery(filterQueryWhiteList(setQueryString)),
        ...options,
      }
      marketStore.moreProductOptions = {
        options,
        statuses,
      }
      options['q[opaque_id]'] = route?.path
      promises.push(marketStore.getProducts({ options, statuses }))
    }

    if (isFirstPage) {
      const self_category = marketStore.selfCategory
      const options = {
        'q[opaque_id]': route?.path,
        'q[category_id_in]': categoryId,
      }
      if (self_category?.data?.view_mode === 'products') {
        options['q[response_type]'] = 'category'
        options['q[category_id_in]'] = self_category?.data?.id
      }

      if (self_category?.data?.view_mode === 'products_and_categories') {
        options['q[response_type]'] = 'multicategory'
        options['q[category_id_in]'] = marketStore.categories
          .reduce((acc, category) => {
            if (category.view_mode === 'products')
              acc.push(category.category_id)
            else if (category.view_mode === 'products_and_categories') {
              acc.push(...category.child_categories)
            }
            return acc
          }, [])
          .join(',')
      }
      // promises.push(marketStore.getSeoTags(options))
    }

    if (categoryId) {
      promises.push(
        new Promise(resolve =>
          marketStore.getSeoFaq(categoryId).then(resp => {
            marketStore.seoFaqData = resp
            resolve(resp)
          }),
        ),
      )
    }

    try {
      await Promise.all(promises)
    } catch (e) {
      console.log('ERROR:ASYNCDATA:categories_slug', e)
    }

    if (process.browser) {
      if (document.getElementsByClassName('Layout')[0]?.scrollTop) {
        document.getElementsByClassName('Layout')[0].scrollTop = 0
      }
    }
    const PopularNowOptions = {
      page: 1,
      per_page: 25,
      'q[has_badge_hot]': true,
      'q[s]': 'id random',
      'q[response_mode]': 'products',
      'q[category_id_in]': categoryId,
      include_fields:
        'id,old_price,retail_price,availability,preorder_available,default_offer_id,img_url_thumbnail,name,manufacturer,avail_check,status,slugged_name,discount,default_marketing_name,ratings,offers,offers.retail_price,offers.id,offers.marketing_name,offers.merchant_uuid,category_id,default_offer_allow_qty,offers.uuid,offers.partner_rating,default_merchant_rating,qty,default_stock_control_mode,default_show_stock_qty_threshold,best_installment_offer_id,offers.supplier_id,is_bulk_heavy,default_merchant_uuid,offers.seller_marketing_name,loyalty_cashback,product_labels,categories,min_qty,discounted,offers.installment_enabled,offers.max_installment_months,offers.avail_check,offers.old_price,offers.old_price,non_refundable,offers.seller_id,offers.seller_marketing_name,market_lottery_default,market_lottery_premium,offers.product_offer_labels,categories.path_ids',
    }
    await marketStore.getPopularNow(PopularNowOptions)

    return {
      view_mode,
      is_adult,
      categoryId,
    }
  },
  data() {
    return {
      // view_mode: 'categories',
      source: 'item_list',
    }
  },
  head() {
    const scripts = []
    const seoScript = this.getSeoFaq()
    const seoPopular = this.getSeoPopular()
    if (seoScript) scripts.push(seoScript)
    if (seoPopular) scripts.push(seoPopular)
    let title = `${this.marketStore.selfCategory?.data?.name} ${
      this.checkIsCategoryElektronika()
        ? this.$t('catalog_title_credit')
        : this.$t('catalog_title')
    }${
      this.marketStore.pagination.page > 1
        ? ' - ' + this.$t('page') + ' ' + this.marketStore.pagination.page
        : ''
    }`
    let description = `${this.marketStore.selfCategory?.data?.name} ${this.$t(
      'catalog_description',
    )} ${
      this.marketStore.pagination.page > 1
        ? '- ' + this.$t('page') + ' ' + this.marketStore.pagination.page
        : ''
    }`
    if (this.marketStore.selfCategory?.data?.node_type === 'NODE') {
      title = this.marketStore.selfCategory?.data?.seo_title
        ? this.marketStore.selfCategory?.data?.seo_title
        : title
      description = this.marketStore.selfCategory?.data?.seo_description
        ? this.marketStore.selfCategory?.data?.seo_description
        : description
    }

    const meta = [
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:title',
        name: 'og:title',
        content: `${this.marketStore.selfCategory?.data?.name} - ${this.$t(
          'product_title',
        )}${
          this.marketStore.pagination.page > 1
            ? ' - ' + this.$t('page') + ' ' + this.marketStore.pagination.page
            : ''
        } ${this.$t('domen_seo')}`,
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: `${this.marketStore.selfCategory?.data?.name} ${this.$t(
          'catalog_description',
        )} ${
          this.marketStore.pagination.page > 1
            ? '- ' + this.$t('page') + ' ' + this.marketStore.pagination.page
            : ''
        }`,
      },
      {
        hid: 'al:android:package',
        property: 'al:android:package',
        content: 'com.competo.umicoloy',
      },
      {
        hid: 'al:android:url',
        property: 'al:android:url',
        content: 'applinks://categories',
      },
      {
        hid: 'al:android:app_name',
        property: 'al:android:app_name',
        content: 'Android-Umico',
      },
      {
        hid: 'al:ios:url',
        property: 'al:ios:url',
        content: 'com.competo.umicoprod',
      },
      {
        hid: 'al:ios:app_store_id',
        property: 'al:ios:app_store_id',
        content: '1458111389',
      },
      {
        hid: 'al:ios:app_name',
        property: 'al:ios:app_name',
        content: 'Umico',
      },
      {
        hid: 'al:web:url',
        property: 'al:ios:app_name',
        content: 'https://umico.az/categories',
      },
    ]

    // Esli outlet to zapreshaem indeksaciyu
    if (+this.categoryId === 3890) {
      meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow',
      })
    }

    return {
      title,
      meta,
      link: [
        {
          rel: 'alternate',
          href: this.$config.BASE_URL + this.$route.fullPath,
          hreflang: this.$i18n?.locale || '',
        },
        {
          rel: 'alternate',
          href: this.getAlternateHref(),
          hreflang: this.getHrefLang(),
        },
      ],
      script: scripts,
    }
  },
  computed: {
    ...mapWritableState(useCustomer, ['userProfile']),
    ...mapState(useMarketStore, ['categories', 'breadcrumbs']),
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    setSourceForCreditLine() {
      this.view_mode
      const source =
        this.view_mode === 'products_and_categories'
          ? 'catalog'
          : this.view_mode === 'categories'
          ? 'catalog'
          : 'item_list'
      return source
    },
  },
  watch: {
    productFilters(query) {
      const defaultQueries = { ...this.$route.query }
      Object.keys(defaultQueries).forEach(key => {
        if (/q\[([^']*)]/.test(key)) {
          delete defaultQueries[key]
        }
      })
      this.$router
        .replace({ query: { ...defaultQueries, ...query } })
        .then(() => {
          this.viewItemList()
        })
        .catch(error => {
          if (error.name !== 'NavigationDuplicated') throw error
        })
    },
    $route: {
      handler(from, to) {
        initBanners(this.$config.ENVIRONMENT)
        this.marketStore.loadingProducts = false

        this.productFiltersFunc()
        if (from?.params?.slug !== to?.params?.slug) {
          this.marketStore.truncated = true
          this.marketStore.showPagination = 20
        }
      },
      deep: true,
    },
  },
  watchQuery: true,
  destroyed() {
    this.marketStore.truncated = true
    this.marketStore.showPagination = 20
  },
  updated() {
    if (this.view_mode === 'categories') {
      this.marketStore.products = []
    }
    // if (['products_and_categories', 'products'].includes(this.view_mode)) {
    //   this.viewItemList()
    // }
  },
  async mounted() {
    const statuses = {
      'q[market_order]': true,
    }
    initBanners(this.$config.ENVIRONMENT, this.categoryId)
    this.marketStore.loadingProducts = false
    if (this.view_mode === 'categories') {
      this.marketStore.products = []
    }

    if (['products_and_categories', 'products'].includes(this.view_mode)) {
      this.viewItemList()
    }
    if (this.isAuthenticated) {
      if (!this.userProfile) {
        await this.getUser()
        const featureMGMStore = useFeatureMGMStore()
        await featureMGMStore.getPreScoringBanner()
      }
      await this.PromoCodeStore.GET_CUSTOMER_PACKAGE()
      await this.LotteryStore.GET_LOTTERY()
      if (!this.marketStore.minInstallmentAmount) {
        await this.marketStore.getMinInstallmentAmount()
      }
    }
    rtbAnalytics(this.$i18n.locale, {
      eventType: 'category',
      categoryId: `${this.categoryId}`,
    })
    // this._rtb(`_category2_${this.categoryId}`)

    this.bloomreachStore.viewCategory(this.marketStore.products)

    if (this.$route?.query.from_search) {
      this.source = 'search'
    }
    setViewSearchResultsSetup(
      this.$route,
      this.marketStore.pagination?.total_entries,
      this.$i18n.locale,
    )
  },
  methods: {
    ...mapActions(useCustomer, ['getUser']),
    getHrefLang() {
      return this.$i18n?.locale === 'az' ? 'ru' : 'az'
    },
    getAlternateHref() {
      const alternateLang = this.$i18n?.locale === 'az' ? 'ru' : 'az'
      const slug =
        this.marketStore.selfCategory?.data &&
        this.marketStore.selfCategory?.data['slugged_name_' + alternateLang]
      const prefix = alternateLang === 'ru' ? '/ru' : ''
      return (
        (slug &&
          `${this.$config.BASE_URL}${prefix}/categories/${this.marketStore.selfCategory?.data.id}-${slug}`) ||
        ''
      )
    },
    checkIsCategoryElektronika() {
      const parent = this.marketStore.selfCategory?.parent
      return (
        this.marketStore.selfCategory?.data?.id === 1 ||
        parent?.data?.id === 1 ||
        parent?.parent?.data?.id === 1 ||
        parent?.parent?.parent?.data?.id === 1
      )
    },
  },
}
