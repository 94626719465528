import { render, staticRenderFns } from "./MPHeader.vue?vue&type=template&id=1ab308f2"
import script from "./MPHeader.vue?vue&type=script&lang=js"
export * from "./MPHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketMPBreadCrumbs: require('/usr/src/app/components/Market/MPBreadCrumbs.vue').default})
